'use strict';

angular.module("loginApp")
    .controller("LoginController", LoginController);

LoginController.$inject = ['$scope', "$rootScope", "$state", "$uibModal", '$http', 'Notification', 'CommonService',
    'SessionService', 'routesConstant', 'rolesConstant', 'rbacService', 'allPermissions'];

function LoginController($scope, $rootScope, $state, $uibModal, $http, Notification, CommonService, SessionService,
                         routesConstant, rolesConstant, rbacService, allPermissions) {

    angular.extend($scope, {
        login: login,
        submit: submit,
        sendEmail: sendEmail
    });

    (function init() {
        $scope.rolesConstant = rolesConstant;
        setDefaultRole();

        redirectToMainLogin();
        checkLiveSession();


        if(!$scope.submitAction || $scope.submitAction.length === 0) {
            $scope.submitAction = 'login';
        }

        $rootScope.page = {
            title: undefined,
            type: undefined,
            instructions: "(To Be Added)",
            actions: [],
            showInstructions: showInstructionsModal
        };

        var $header = $(".header");
        $header.css("position", "relative");

    })();


    function setDefaultRole() {
        if($state.params && $state.params.role) {
            $scope.role = $state.params.role;
        } else {
            $scope.role = undefined;
        }
    }

    /**
     * Login as different roles [1] Customer [2] Admin [3] MFG this is the order of priority for which portal login.
     * @return {String}
     */
    function determineDefaultPortal() {
        return allPermissions.getAllPermissions()
            .then(function () {

                var appAccess = SessionService.getAppAccess();

                var roles = getRolesByPriority();

                for (var i in roles) {
                    if (appAccess.indexOf(roles[i]) >= 0) {
                        return roles[i];
                    }
                }

                // nothing found, default to customer
                return rolesConstant.CUSTOMER;
            });
    }

    function showInstructionsModal() {
        $uibModal.open({
            templateUrl: "/static/templates/login/afl-page-instructions-modal.html",
            resolve: {
                page: function () {
                    return $scope.page;
                }
            },
            controller: ["$scope", "page", function ($scope, page) {
                $scope.page = page;
            }]
        });
    }

    function getRolesByPriority() {
        var roles = [rolesConstant.CUSTOMER, rolesConstant.ADMIN, rolesConstant.MFG, rolesConstant.LINK];

        if ($scope.role && roles.indexOf($scope.role) >= 0) {
            return _.union([$scope.role], roles);
        }

        return roles;
    }

    function sendEmail(email) {
        if (typeof email !== "string") {
            Notification.error('Please enter your email');
        } else {
            var url;

            if ($scope.role === rolesConstant.ADMIN) {
                url = $scope.resetPWUrl + encodeURIComponent(email);
            } else {
                url = "/sendResetEmail/" + encodeURIComponent(email);
            }

            $http.post(url)
                .then(function (rsp) {
                    Notification.success("Sent reset password link to " + email);
                }, function(rsp) {
                    if (_.get(rsp, 'data.reason') && "UserNotFound" === rsp.data.reason) {
                        Notification.error("Email address not found in the system");
                    } else {
                        Notification.error("An error occurred sending the reset password link");
                    }
                });
        }
    }

    function goToState(orgCount, stateName){
        var LINK = $scope.LINK = routesConstant.LINK;
        if (orgCount !== 0){
            $state.go(stateName);
        }
        else if ($scope.role === "link") {
            $state.go(stateName) ;
        }
        else {
            Notification.error(
                {
                    message: 'The user is missing an associated organization. Please contact tech support.',
                    positionY: 'top'
                });
        }
    }

    function login() {
        var isAerosLinkUser = false ;
        CommonService.authenticateProfile($scope.email, $scope.password, $scope.forcedLogin)
            .then(function(response) {

                SessionService.setSessionInfo(response.data);
                if (response && response.data && response.data.roles ==="aeRosLinkUser") {
                    isAerosLinkUser = true ;
                }
                CommonService.userProfile = response.data;

                return determineDefaultPortal()
                    .then(gotoPortal(response.data));
            })
            .catch(function(response) {
                if (response.status === 403 ) {
                    Notification.error(response.data.detail || "Your login request was denied");
                    $scope.hasExistingSession = true;
                } else if(response.status === 401 || response.status === 500 ){
                    if (!isAerosLinkUser) {
                        Notification.error(
                            {
                                message: 'The Email and/or Password you entered is not valid, Please try again or use Forgot Password.',
                                positionY: 'top'
                            });
                    }
                    else {
                        Notification.error(
                            {
                                message: 'You must be looking for aeRosLink. Please log in to TRM provided with your FlexScan to access your data files in aeRosLink.',
                                timeOut:  10000,
                                positionY: 'top'
                            });
                    }
                }

            });
    }

    function gotoPortal(sessionInfo) {

        return function(role) {
            $scope.role = role;
            switch ($scope.role) {
                case rolesConstant.ADMIN:
                    $state.go(routesConstant.ADMIN.DASHBOARD.stateName);
                    break;
                case rolesConstant.MFG:
                    $state.go(routesConstant.MFG.DASHBOARD.stateName);
                    break;
                default:
                    if ($scope.role === "link") {
                        // This is a special case when we have an aeRosLink customer
                        // registered by FlexScan app with no organization

                        $scope.role= null ;
                        $scope.hasExistingSession = false;
                        SessionService.setSessionInfo(null);
                        CommonService.userProfile = null;
                        Notification.error(
                            {
                                message: 'You must be looking for aeRosLink. Please log in to TRM provided with your FlexScan to access your data files in aeRosLink.',
                                timeOut:  10000,
                                positionY: 'top'
                            });

                        ///// AD-40 changed: goToState(0, routesConstant.LINK.DASHBOARD.stateName);
                    }
                    else {
                        goToState(sessionInfo.orgCount, routesConstant.CUSTOMER.PROJECTS.stateName);
                    }
            }
            $scope.hasExistingSession = false;
        }

    }

    function submit(email, password, forcedLogin) {
        $scope.email = email;
        $scope.password = password;
        $scope.role = this.role;
        $scope.forcedLogin = forcedLogin;
        $scope.hasExistingSession = this.hasExistingSession;
        $scope.submitted = true;
        login();
    }

    function redirectToMainLogin() {
        if ([
            routesConstant.LOGIN.CUSTOMER.stateName,
            routesConstant.LOGIN.ADMIN.stateName,
            routesConstant.LOGIN.MFG.stateName,
            routesConstant.LOGIN.LINK.stateName]
            .indexOf($state.current.name) >= 0) {
                $state.go(routesConstant.LOGIN.MAIN.stateName);
        }
    }

    function checkLiveSession() {
        var sessionInfo = SessionService.getSessionInfo();

        if (sessionInfo) {
            determineDefaultPortal()
                .then(gotoPortal(sessionInfo));
        }
    }
}
